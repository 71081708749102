import { createRouter, createWebHistory } from "vue-router";
import { scrollTo } from '@/utils.js';
import Home from "@/pages/Home.vue";
import ApiDoc from "@/pages/ApiDoc.vue";
import ApiSend from "@/pages/ApiSend.vue";
import ApiDLR from "@/pages/ApiDLR.vue";
import ApiMisc from "@/pages/ApiMisc.vue";
import NotFound from "@/pages/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "forside",
    component: Home,
    meta: { 
      title_se: 'SMS-API och SMS-gateway för företag och organisationer',
      title_dk: 'SMS-API og SMS-gateway for virksomheder og organisationer',
      description_se: 'Sveve erbjuder SMS-gateway till företag och organisationer. En robust tjänst som ger dig superkrafter inom mobilkommunikation! Prova gratis!',
      description_dk: 'Sveve tilbyder SMS-gateway til virksomheder og organisationer. En robust tjeneste, der giver dig superkræfter inden for mobilkommunikation! Prøv gratis!',
    }
  },
  {
    path: "/apidok",
    redirect: "/apidok/send",
    name: "apidok",
    component: ApiDoc,
    meta: {
      title_se: 'API documentation',
      title_dk: 'API documentation',
    },
    children: [
      {
        path: 'send',
        name: 'ApiSend',
        component: ApiSend,      
      },
      {
        path: 'dlr',
        name: 'ApiDLR',
        component: ApiDLR,      
      },
      {
        path: 'misc',
        name: 'ApiMisc',
        component: ApiMisc,      
      }        
    ]    
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound
  }
];


const router = createRouter({
  history: createWebHistory(),
  scrollBehavior (to/*, from, savedPosition*/) {
    if (to.hash) {
        scrollTo(to.hash);
        return { el: to.hash }
    }
    else {
        return { top: 0 }
    }
  },
  routes: routes
});

export default router