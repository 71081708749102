export function getTopDomain() {
    let topDomain = 'no';
    const hostname = location.hostname;
    if (hostname === 'localhost') {            
        const params = new URLSearchParams(window.location.search);
        if (params.has('topdomain')) {
            topDomain = params.get('topdomain');
        }
        else if (process.env.VUE_APP_TOP_DOMAIN) {  // prerender script 
            topDomain = process.env.VUE_APP_TOP_DOMAIN;
        }   
    }
    else {            
        topDomain = hostname.substring(hostname.length-2, hostname.length);
    }
    //console.log('topDomain', topDomain);
    return topDomain;
}

export function scrollTo(divId) {
    if (divId.startsWith('#')) {
        divId = divId.substring(1);
    }
    const element = document.getElementById(divId);
    if (element) {
        element.scrollIntoView({ block: 'center',  behavior: 'smooth' });
    }
}