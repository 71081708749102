<template>
    <footer v-if="dataLoaded" class="mt-6 px-6 sm:px-40 py-10 text-gray-200">

        <div class="text-2xl font-semibold">{{ tryNowHeading }}</div>
        <div>{{ tryNowText }}</div>
        <router-link to="/#order" class="btn btn-orange mt-8">{{ tryNowBtn }}</router-link>

        <div class="flex flex-wrap justify-between mt-10 sm:mt-16">
            <div class="lg:w-2/5 lg:pr-40 pb-6">
                <img src="/img/symbol-1.svg" alt="">
                <div class="mt-2">{{ generalText }}</div>
            </div>
            <div class="lg:w-1/5">
                <div class="footer-heading">{{ col1Heading }}</div>
                <div v-for="(link, i) in col1Links" :key="i">
                    <a v-if="isExternalLink(link.url)" :href="link.url">{{ link.text }}</a>
                    <router-link v-else :to="link.url">{{ link.text }}</router-link>
                </div>
            </div>
            <div class="lg:w-1/5">
                <div class="footer-heading">{{ col2Heading }}</div>
                <div v-for="(link, i) in col2Links" :key="i">
                    <a v-if="isExternalLink(link.url)" :href="link.url">{{ link.text }}</a>
                    <router-link v-else :to="link.url">{{ link.text }}</router-link>
                </div>
            </div>
            <div class="lg:w-1/5">
                <div class="footer-heading">{{ col3Heading }}</div>
                <div v-for="(text, i) in col3Text" :key="i">
                    <div>{{ text }}</div>
                </div>
            </div>
        </div>

    </footer>
</template>

<script>
import { Settings } from '@/main.js';
import * as contentful from 'contentful';
import { getTopDomain } from '@/utils.js';

export default {
    data() {
        return {
            dataLoaded: false,
            tryNowHeading: '',
            tryNowText: '',
            tryNowBtn: '',
            generalText: '',
            col1Heading: '',
            col1Links: [],
            col2Heading: '',
            col2Links: [],            
            col3Heading: '',
            col3Text: [],              
        }
    },
    created: function() {
        const topDomain = getTopDomain();
        this.getContent(topDomain);
    },    
    methods: {
        getContent: function(topDomain) {
            const cmsClient = contentful.createClient({
                space: Settings.contentful.space, 
                accessToken: Settings.contentful.accessToken
            });                
            cmsClient.getEntries({ content_type: 'footer', 'fields.topDomain': topDomain }).then((entries) => {                   
                if (entries.items && entries.items.length) {
                    let contentJson = entries.items[0].fields.content;
                    for (let prop in contentJson) {
                        this[prop] = contentJson[prop];
                    }
                }
                this.dataLoaded = true;
            });            
        },
        isExternalLink: function(linkUrl) {
            return linkUrl.startsWith('http');            
        },        
    }    
}
</script>