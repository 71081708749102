<template>
    <div>         
        <h1>API Documentation</h1>
        <div class="pt-6 pb-4 relative">
            <div class="absolute w-full -mt-16">
                <img style="float:right" src="/img/plane-green-left.svg" alt="">
            </div>

            <div class="flex flex-wrap justify-start">                
                <div v-for="(page,i) in pages" :key="i" class="relative px-2 pt-2 sm:px-6 text-center" @mouseover="hoverPage=page.path" @mouseleave="hoverPage=''">
                    <router-link :to="{name: page.name}" :class="{'font-bold' : activePage === page.path}" @click.passive="activePage = page.path" class="font-semibold text-gray-900 no-underline hover:no-underline">
                        {{ page.alias }}
                    </router-link>
                    <div v-if="activePage === page.path || hoverPage === page.path" class="w-full absolute -ml-2 sm:-ml-6 mb-4 border-b-4 border-blue-600"></div>
                </div>
            </div>
            <div class="border-t-2 border-dashed"> </div>
        </div>

        <router-view :key="$route.fullPath"></router-view>
    </div>

</template>

<script>

export default {

    data() {
        return {
            activePage: '',
            hoverPage: '',
            pages: [
                { path: 'send', name: 'ApiSend', alias: 'Send SMS' },
                { path: 'dlr', name: 'ApiDLR', alias: 'Delivery report' },                
                { path: 'misc', name: 'ApiMisc', alias: 'Order SMS' },
            ],            
        }
    },
    created: function() {    
        let path = this.$route.path;
        if (path.endsWith('/')) { 
            path = path.substring(0, path.length-1);
        }
        let p = path.split('/');        
        this.activePage = p[p.length-1];    
    },
}

</script>