import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import router from './router';
import './css/tailwind.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { VueCookieNext } from 'vue-cookie-next';
import VueGtag from 'vue-gtag-next';


export const Settings = {  
    contentful: {
        space: '57ckoh6wm6t5',
        accessToken: 'NK_CXoYXO2GOCAVDd5PaC44qyJ7mO6lNmDw0CDeHSfI'    
    },
    server: {
        api: process.env.VUE_APP_API_URL,
    },
};

const store = createStore({
    state() {
        return {
            showNavbar: true,
            showFooter: true,
            //products: [],
            //trialOrder: {}
        }
    },
    mutations: {

    },
    actions: {

    },
    getters: {

    }
});


const app = createApp(App);

app.use(router);

app.use(VueAxios, axios);
app.use(store);
app.use(VueCookieNext);

app.use(VueGtag, {
    property: {
      id: 'G-TYR7MM496B'
    }
});

app.mount('#app');
