<template>

    <!--h2>Send SMS - HTTP GET/POST</h2-->

    <div class="pt-2 pb-6">
        Send SMS example (get):
        <div class="api-example">
            https://api.sveve.{{ getTopDomain() }}/SMS/SendMessage?user=xxx&passwd=xxx&to=xxx&msg=Text&f=json
        </div>
    </div>

    <APIdocTable :tableData="tableData" />    

    <div class="pt-6 pb-4">
        Max GET request size: 6900 characters
    </div>

    <div class="mt-4">
        3 alternatives for POST:
    </div>    
    <div class="text-base">        
        <div class="mt-3">
            <div class="font-semibold">1. Body JSON</div>
            <div>Three options. The examples shows only some of the parameters, however all parameters are supported.</div>           
            <div class="mt-1 text-sm" v-for="(ex, i) in jsonRequestExample" :key="i">
                <div class="font-semibold">{{ ex.heading }}:</div>
                <div class="overflow-x-auto text-gray-700"><pre>{{ ex.json }}</pre></div>
            </div>     
        </div>

        <div class="mt-3">
            <div class="font-semibold">2. Body parameter</div>
            <div>Form-data parameters</div>
        </div>

        <div class="mt-3"> 
            <div class="font-semibold">3. URL parameter</div>
            <div>Same as GET but with POST as method. Parameters in URL, not in request body.</div>
        </div>
        
        <div class="mt-5"> 
            Set ContentType=UTF-8 in all POST requests.
        </div>

        <!--div class="mt-2">
            Download <a href="../w1/SveveAPI-postman.json" target="_blank">Postman templates</a> for all alternatives.
        </div-->
    </div>

    <!-------------------------------------------------------------------------------------------------------------------->
    
    <div class="font-semibold mt-6">Return from SMS requests</div>
    <div>
        Each request will return some information, both for GET and POST. We recommend using JSON as return format (f=json).
    </div>
    <div class="py-4">
        <div class="pt-2 text-sm">                        
            <div class="pb-2" v-for="(ex, i) in jsonResponseExample" :key="i">
                <div class="font-semibold">{{ ex.heading }}:</div>
                <div class="overflow-x-auto text-gray-700"><pre>{{ ex.json }}</pre></div>
            </div>                    
        </div>
        
        <div class="pt-2 pb-1 font-semibold">Response object</div>
        <div class="overflow-x-auto">
            <table class="api-table">
                <thead>
                    <tr><th>Key</th><th>Type</th><th>Description</th></tr>
                </thead>
                <tbody>
                    <tr v-for="(response,r) in responseDesc" :key="r">
                        <td v-html="response.key"></td>
                        <td v-html="response.type"></td>
                        <td v-html="response.desc"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-------------------------------------------------------------------------------------------------------------------->

    <div>
        <div class="font-semibold mt-6">Charset and characters per sms unit</div>
        <div>
            The following characters are part of the GSM 7-bit specification. For these characters each sms unit contains 160 characters.
            For longer messages each unit contains 153 characters because 7 characters are used for header in each part. The header is essential for correct unit assembly order on the receivers phone.
            Sveve supports messages up to 1071 characters.
            <div class="text-base mt-3 mb-4 whitespace-normal">
                <div class="font-semibold">GSM-7</div>
                <span v-for="(c, i) in gsm7chars" :key="i" class="mr-1 inline-block">{{c}}</span>
            </div>
            If other characters exists in the message text, each unit will contain 70 characters. Sveve supports all Unicode-characters with UTF-8 encoding, including emojis.
        </div>        
    </div>

    <!-------------------------------------------------------------------------------------------------------------------->
    
    <div>
        <div class="font-semibold mt-6">Concurrent request limitations</div>
        <div class="text-base">
            To ensure a reliable and fast service, there is a limit on number of concurrent requests from each account.
            <div class="mt-2">
                The limit is <span class="font-semibold">5</span> concurrent API-requests
            </div>
            <div class="mt-3">
                <div class="font-semibold">Tips on how to setup the integration:</div>
                <ul>
                    <li>Read the response on the previous request before sending the next</li>
                    <li>If the limit is reached the server will respond with http status 429 (Too Many Requests)</li>
                    <li>Every request only takes a few milliseconds. You can send as many messages as you like, but you need to limit the number of concurrent requests, or handle the 429 status.</li>
                </ul>
            </div>
            <div class="mt-4">
                Need more concurrent requests?<br>            
                If you need to increase the concurrent request limit, send us on e-mail: post@sveve.{{ getTopDomain() }}. 
                This could be if you send requests from multiple servers or if you have other reasons to make multiple concurrent requests.          
            </div>
        </div>        
    </div>
    <end-page />
</template>

<script>
import APIdocTable from '@/components/APIdocTable.vue';
import { getTopDomain } from '@/utils.js';

export default {
    components: {
        APIdocTable
    },
    data() {
        return {
            tableData: {
                columns: [ 'Parameter', 'Type', 'Max length', 'Mandatory', 'Default', 'Description' ],
                highlightColumn: 0,
                rows: [
                    ['user', 'String', '', true, '', 'Username'],
                    ['passwd', 'String', '', true, '', 'API password. You find this in the welcome e-mail from Sveve'],
                    ['to', 'String', '', true, '', 'Receivers mobile number (comma separated if multiple), and/or existing group name'],
                    ['msg', 'String', '1071', true, '', 'Message text, UTF-8 encoded.'],
                    ['from', 'String', '11', false, '27333', 'Sender showing on receivers phone (eg. company name or your mobile number). No special characters. Minimum 3 characters is recommended to support iPhone.'],
                    //['reply', 'Boolean', '', false, 'false', 'Set reply option. If true sender will be a 14-digit number (autogenerated).'],
                    //['reply_id', 'Integer', '', false, '', 'ID on previous sent message (with reply=true). This will keep the message in the same message thread on receivers phone.'],
                    ['date_time', 'String', '12', false, '', 'Date/time for scheduled message. Format: yyyyMMddttmm. Eg: 202310061315 (6. okt. 2023 @ 13:15). NB! max 5000 characters in "to"-parameter for scheduled messages.'],
                    //['reoccurrence', 'String', '', false, '', 'Send message multiple times (require original message sent with date_time). Format: value|unit<br>Values:<br>11=hour, 5=day, 99=week, 2=month<br>Eg. 1: 6|11 (every 6th hour)<br>Eg. 2: 14|5 (every 14th day)'],
                    //['reoccurrence_ends', 'String', '', false, 'never', 'Accepted values: never, after, on<br>never=reoccurrence never stops<br>after=reoccurrence stops after a set count<br>on=reoccurrence stops after a date'],
                    //['ends_after', 'Integer', '', false, '', 'Required if reoccurrence_ends=after. Reoccurrence stops after the provided count value.'],
                    //['ends_on', 'String', '', false, '', 'Required if reoccurrence_ends=on. Reoccurrence stops after provided date (format: dd.MM.yyyy), ex: 31.12.2024'],
                    ['ref', 'String', '36', false, '', 'Your own reference. This will be included in delivery reports.'],
                    ['f', 'String', '', false, 'xml', 'Return format, valid options: json, xml'],
                    ['test', 'Boolean', '', false, 'false', 'Set this to \'true\' for testing without sending SMS'],
                ]
            },            
            jsonRequestExample: [
                {
                    heading: 'Option 1 (single message to one or more recipients)',
                    json: {
                        "user": "username",
                        "passwd": "api-password",
                        "to": "number1, number2, ..",
                        "msg": "Message text",
                        "from": "MyCompany",
                        "f": "json",
                        "test": false
                    }
                },
                {
                    heading: 'Option 2 (single message to one or more recipients)',
                    json: {
                        "request": {
                            "user": "username",
                            "passwd": "api-password",
                            "to": "number1, number2, ..",
                            "msg": "Message text",
                            "from": "MyCompany",
                            "f": "json",
                            "test": false
                        }
                    }
                },
                {
                    heading: 'Option 3 (multipple messages)',
                    json: {
                        "user": "username",
                        "passwd": "api-password",
                        "f": "json",
                        "test": false,
                        "messages": [
                            {
                                "to": "number1",
                                "msg": "Message text 1",
                                "from": "MyCompany"
                            },
                            {
                                "to": "number2",
                                "msg": "Message text 2",
                                "from": "MyCompany"
                            }
                        ]
                    }                    
                }                             
            ],            
            jsonResponseExample: [
                {
                    heading: 'Successful (2 messages sent)',
                    json: {
                        "response": {
                            "msgOkCount": 2,
                            "stdSMSCount": 4,        
                            "ids": [42824111, 42824112]        
                        }
                    } 
                },
                {
                    heading: 'Failed (no messages sent)',
                    json: {
                        "response": {
                            "msgOkCount": 0,
                            "stdSMSCount": 0,        
                            "fatalError": "Feil brukernavn/passord",
                            "ids": []
                        }
                    }
                },
                {
                    heading: 'Partial success (1 message sent and 2 rejected)',
                    json: {
                        "response": {
                            "msgOkCount": 1,
                            "stdSMSCount": 1,        
                            "errors": [        
                                {
                                "number": "1792873691",
                                "message": "Brukeren har ikke tilgang til å sende meldinger til dette landet"
                                },
                                {
                                "number": "63987654",
                                "message": "Telefonnummeret er ikke et mobilnummer"
                                }
                            ],        
                            "ids": [42824387]        
                        }
                    }
                }
            ],
            responseDesc: [
                {
                    key: 'msgOkCount',
                    type: 'Integer',
                    desc: 'Number of messages sent'
                },
                {
                    key: 'stdSMSCount',
                    type: 'Integer',
                    desc: 'Total number of SMS units for this request. One SMS unit is limited to 160 characters.'
                },
                {
                    key: 'fatalError',
                    type: 'String',
                    desc: 'Error description if request failed'
                },
                {
                    key: 'errors',
                    type: 'Array',
                    desc: 'Error description if request partial failed'
                },
                {
                    key: 'ids',
                    type: 'Array',
                    desc: 'IDs for sent messages. The order matches the mobile number order in request.'
                },                                                
            ],
            gsm7chars: ['@','£','$','¥','è','é','ù','ì','ò','Ç','Ø','ø','Å','å','?','_','F','G','?','O','?','?','S','T','?','^','{','}','\\','[','~',']','|','€','Æ','æ','ß','É','!','"','#','¤','%','&','\'','(',')','*','+',',','-','.','/','0','1','2','3','4','5','6','7','8','9',':',';','<','=','>','?','¡','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','Ä','Ö','Ñ','Ü','§','¿','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','ä','ö','ñ','ü','à'],
        }
    },
    methods: {
        getTopDomain,
    }
}
</script>

<style scoped>

ul {
    list-style-type: circle;
    list-style-position: outside;    
    margin-left: 20px;    
}
ul li {
    margin-top: 5px;
}

</style>