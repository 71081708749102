<template>
    <!--h2>Delivery report</h2-->    
    <div>
        Messages will sometimes not reach the recipient. There are different reasons for this happening, eg. invalid or inactive mobile number.
        The delivery report will give you information on the delivery status for each message sent. 
        The report will be sent to your server. You can choose GET or POST method.
    </div>    
    <div class="my-4">
        Send us your server endpoint address and we will configure this on your account: <a :href="'mailto:post@sveve.' + getTopDomain()">post@sveve.{{ getTopDomain() }}</a>
    </div>

    <!--div class="pt-4">
        <span>You can configure your URL on the page <strong>API -> Innstillinger</strong>:</span>        
    </div-->
    
    <!--div class="my-6 pb-2 border border-gray-300 shadow-lg inline-block">
        <img src="/img/api-help-dlr.png" alt="">
    </div-->
    
    <div class="py-2 font-semibold">Parameters</div>
    <div class="overflow-x-auto">
        <table class="api-table">
            <thead><tr><th>Parameter</th><th>Type</th><th>Description</th></tr></thead>
            <tbody>
                <tr v-for="(p,i) in params" :key="i">
                    <td v-html="p.param"></td>
                    <td v-html="p.type"></td>
                    <td v-html="p.desc"></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="pt-6 pb-2">
        <span>Request example (get):</span>
    </div>
    <div class="api-example">
        https://your-domain.com/sms_dlr.php?number=4593654321&status=true&id=42824112&ref=ABC123&errorCode=&errorDesc=        
    </div>
    <div class="my-2 text-sm">In this example <span class="font-semibold">https://your-domain.com/sms_dlr.php</span> is your endpoint address</div>

    <ApiDocPushInterval />
    <end-page />
</template>

<script>
import ApiDocPushInterval from '@/components/APIdocPushInterval.vue';
import { getTopDomain } from '@/utils.js';

export default {
    components: {
        ApiDocPushInterval,
    },    
    data() {
        return {            
            params: [
                {
                    param: 'number',
                    type: 'Long',
                    desc: 'Recipient mobile number'
                },
                {
                    param: 'status',
                    type: 'Boolean',
                    desc: 'true: message sent ok<br>false: message could not be sent'
                },
                {
                    param: 'id',
                    type: 'Integer',
                    desc: 'Message ID'
                },
                {
                    param: 'ref',
                    type: 'String',
                    desc: 'Your reference'
                },
                {
                    param: 'errorCode',
                    type: 'String',
                    desc: 'Error code'
                },
                {
                    param: 'errorDesc',
                    type: 'String',
                    desc: 'Error description'
                },                                                                                
            ]            
        }
    },
    methods: {
        getTopDomain,
    }
}
</script>