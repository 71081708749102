
<template>

  <header class="bg-white fixed w-full sm:flex justify-between sm:px-4 sm:py-6 z-10" :class="atTopOfPage && !isOpen ? '' : 'shadow-2xl'">
    <div class="flex items-center justify-between px-4 py-3 sm:p-0">
      <div>
        <router-link to="/" @click.passive="closeMenu" class="focus:shadow-none"><img class="h-8 sm:h-10 sm:ml-4" src="/img/logo-sveve.svg" alt="Sveve logo"></router-link>
      </div>
      <div class="sm:hidden">
        <button @click="toggleMenu()" type="button" class="block text-gray-500 hover:text-gray-700 focus:shadow-none">
          <svg class="h-8 w-8 fill-current" viewBox="0 0 24 24">
            <path v-if="isOpen" fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
            <path v-if="!isOpen" fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
          </svg>
        </button>
      </div>
    </div>

    <nav id="menu" :class="isOpen ? 'block' : 'hidden'" class="px-2 pt-2 pb-4 sm:flex sm:p-0 lg:text-lg">        

        <router-link to="/#prices" @click.passive="closeMenu" ref="prices" class="menuItem">Priser</router-link>
        <!--router-link to="/#about" @click.passive="closeMenu" ref="about" class="menuItem">About</router-link-->
        <router-link to="/#contact" @click.passive="closeMenu" ref="contact" class="menuItem">Kontakt</router-link>
        <router-link to="/apidok" @click.passive="closeMenu" ref="documentation" class="menuItem">API dokumentasjon</router-link>

        <div class="sm:hidden pt-4 mb-4 border-b border-gray-300"></div>
        <span class="sm:ml-auto sm:pl-10 flex justify-end">
            <router-link to="/#order" @click.passive="closeMenu" ref="order" class="btn btn-blue">Prøv gratis</router-link>
            <!--<router-link to="/login" @click.passive="closeMenu" class="menuItem ml-2 highlight">Logg inn</router-link>-->
        </span>
    </nav>
    
  </header>
  <div class="mt-16 sm:mt-32"></div> <!-- dummy for top margin -->

</template>

<script>
import { getTopDomain, scrollTo} from '@/utils.js';

  export default {
      data() {
          return {
              isOpen: false,
              atTopOfPage: true,
              langText: {
                se: {
                  prices: 'Priser',
                  about: 'Om',
                  contact: 'Kontakt',
                  documentation: 'API-dokumentation',
                  order: 'Prova gratis',
                },
                dk: {
                  prices: 'Priser',
                  about: 'Om',
                  contact: 'Kontakt',
                  documentation: 'API-dokumentation',
                  order: 'Prøv gratis',
                }                
              }
          }
      },
      beforeMount () {
          window.addEventListener('scroll', this.handleScroll);
      },
      mounted() {
        this.translate();
      },
      methods: {
          scrollTo,
          handleScroll() {
            this.atTopOfPage = window.scrollY === 0;            
          },
          closeMenu() {
            this.isOpen = false;
          },
          toggleMenu() {
            this.isOpen = !this.isOpen;   
          },
          translate: function() {
            const topDomain = getTopDomain();
            for (const lang in this.langText[topDomain]) {
              const elem = this.$refs[lang];
              if (elem) {
                elem.$el.innerHTML = this.langText[topDomain][lang];
              }
            }
          }
      }
  }
 
</script>
