<template>
    <div class="p-6 sm:p-8 text-center shadow-lg bg-white mb-4">
        
        <div :id="headingId" class="py-4 text-gray-900 text-2xl sm:text-4xl" v-html="heading"></div>
        <div v-html="text"></div>

        <div class="mt-4 text-left xl:ml-64 lg:ml-40 sm:ml-20"> 
            <div class="text-2xl font-semibold mb-4" v-html="apiHeading"></div>
            <div v-for="(apiPrice, p) in apiPrices" :key="p">             
                <div class="flex gap-x-3">
                    <img src="/img/icon/tick.svg">
                    {{ apiPrice }}
                </div>        
            </div>
        </div>

        <div class="mt-8 text-left xl:ml-64 lg:ml-40 sm:ml-20"> 
            <div class="text-2xl font-semibold mb-4" v-html="smsHeading"></div>
            <div v-for="(smsPrice, p) in smsPrices" :key="p">                
                <div class="flex gap-x-3">
                    <img src="/img/icon/tick.svg">
                    {{ smsPrice.count }} {{ getStkLabel() }} - {{ smsPrice.price }} {{ getOreLabel() }}/{{ getStkLabel() }}                         
                </div>
            </div>
            <div class="flex gap-x-3">
                <img src="/img/icon/tick.svg">
                {{ smsPriceBottom }}
            </div>            
        </div>
    </div>
</template>

<script>
import { getTopDomain } from '@/utils.js';

export default {
    name: 'PriceTable',
    props: {
        heading: String,
        headingId: String, 
        text: String,
        apiHeading: String,
        apiPrices: Array,
        smsHeading: String,
        smsPrices: Array,
        smsPriceBottom: String,
    },
    methods: {
        getOreLabel: function() {
            switch (getTopDomain()) {
                case 'se': return 'öre';
                case 'dk': return 'øre';
                default: return 'øre';
            }
        },
        getStkLabel: function() {
            switch (getTopDomain()) {
                case 'se': return 'st';
                case 'dk': return 'stk';
                default: return 'stk';
            }
        }        
    }
}
</script>
