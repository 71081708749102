<template>

    <div class="font-semibold pt-4">
        <span>Number of SMS units remaining (GET)</span>
    </div>
    <div class="api-example"> 
        https://api.sveve.{{getTopDomain()}}/SMS/AccountAdm?cmd=sms_count&user=xxx&passwd=xxx
    </div>

    <div class="font-semibold pt-6">
        <span>Order SMS (GET)</span>
    </div>
    <div class="api-example"> 
        https://api.sveve.{{getTopDomain()}}/SMS/AccountAdm?cmd=order_sms&count=5000&user=xxx&passwd=xxx
    </div>
    <div class="text-sm">        
        <strong>count</strong> 
        <span> is an integer between 500 and 100000</span>
    </div>

    <end-page />
</template>

<script>
import { getTopDomain } from '@/utils.js';

export default {
    methods: {
        getTopDomain,
    }
}
</script>