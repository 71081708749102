<template>
    <div> 
        <h2>Retry intervals</h2>        
        <div>
            If your server is not online or returns an error code, we will try to send the message again with increasing intervals. 
            Time between each try:
        </div>              
        <div class="mt-2 ml-6">
            <div v-for="(int, i) in intervals" :key="i" class="flex">
                <span class="text-right mr-2 w-6">{{int.value}}</span><span>{{int.unit}}</span>                
            </div>
        </div>
    </div>
</template>

<script>
export default {      
    data() {
        return {
            intervals: [
                { value: '5', unit: 'seconds'},
                { value: '15', unit: 'seconds'},
                { value: '1', unit: 'minute'},
                { value: '2', unit: 'minutes'},
                { value: '5', unit: 'minutes'},
                { value: '30', unit: 'minutes'},
                { value: '1', unit: 'hour'},
                { value: '2', unit: 'hours'},
                { value: '4', unit: 'hours'},
            ]
        }
    }
}
</script>