  
<template>
  <div class="flex antialiased">
    <div class="min-h-screen w-full">
      <Navbar v-if="showNavbar" />
      <main class="px-4 sm:px-6 lg:px-12 xl:px-24">
        <router-view :key="$route.fullPath" />
      </main>
      <div v-if="showFooter">
        <Footer style="background-image: url('/img/background-blue.svg')" />
        <div class="text-center py-4 text-sm">
          2006 - {{ new Date().getFullYear() }} &copy; Sveve AS | <!--router-link to="/cookies">Cookies</router-link> |--> <a href="/personvernerklaering.pdf">Personvernerklæring</a>      
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import { getTopDomain } from '@/utils.js';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
  watch: {
    $route(to) {
      this.$store.state.showNavbar = to.meta.hideNavbar !== true;      
      this.$store.state.showFooter = to.meta.hideFooter !== true;  
      document.title = to.meta['title_' + getTopDomain()] || 'Sveve';
      document.querySelector('meta[name="description"]').setAttribute('content', to.meta['description_' + getTopDomain()]);            
    }
  },
  computed: {
    showNavbar() {
      return this.$store.state.showNavbar;
    },
    showFooter() {
      return this.$store.state.showFooter;
    }    
  }
}
</script>
